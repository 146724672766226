import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SubPageContent from "../components/SubPageContent"
import SEO from "../components/seo"
import SmallLogoFull from "../components/SmallLogoFull"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet>
          <title>Borealis | 404</title>
        </Helmet>
        <SEO title="404: Not Found" />
        <SmallLogoFull />
        <SubPageContent title="404 - Not Found">
          <p>You just hit a route that doesn&#39;t exist.</p>
        </SubPageContent>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
